<template>
  <div class="ManageNotifications">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol>
        <h1>Vos notifications</h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CCard v-for="notification in notifications" v-bind:key="notification.id" class="notification-card" @click="clickOnNotification(notification)" >
          <CCardBody>
            <div>
              <small class="text-muted float-right">{{calculateDateDifference(notification.timestamp)}}</small>
            </div>
            <div v-bind:class="['text-truncated', (!notification.clicked) ? 'font-weight-bold' : 'text-muted']">
              <span v-if="!notification.clicked" class="dot mr-2"></span>{{notificationTitleRender(notification.verb)}}
            </div>
            <div class="text-truncated small text-muted mt-1">
              {{notificationDescriptionRender(notification, notification.verb)}}
            </div>
          </CCardBody>
        </CCard>

        <CRow>
          <CCol class="text-center">
            <CButton
              v-if="getAllNotificationsUrl"
              @click="getAllNotifications()"
              class="px-4"
              shape="pill"
              block
              color="outline-dark">
              Voir plus
            </CButton>
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import notificationsMixins from '@/mixins/notificationsMixins'

import { APIUserConnected } from '@/api/APIUserConnected'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';



const apiUserConnected = new APIUserConnected()

export default {
  name: 'ManageNotifications',
  mixins: [
    notificationsMixins
  ],
  components: {
    Loading
  },
  data: function () {
    const now = this.$dayjs()
    return {

      isLoading: false,
      now: now,
      // ------ Notifications  ---
      notifications: [],
      baseGetAllNotificationsUrl: apiBaseUrl + '/all-own-notifications',
      getAllNotificationsUrl: apiBaseUrl + '/all-own-notifications',
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  created: function() {
    this.getAllNotifications()
  },

  methods: {
    getAllNotifications () {
      this.isLoading = true
      apiUserConnected.getAllNotifications(this.token, this.getAllNotificationsUrl)
      .then((result) => {
        this.notifications = this.notifications.concat(result.data.results)
        this.getAllNotificationsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    clickOnNotification (notification) {
      if (notification.clicked) {
        this.$router.push(this.notificationLinkRender(notification.actor, notification.verb))
      }
      else {
        apiUserConnected.markNotificationAsClicked(this.token, notification.id)
        .then(() => {
          this.$router.push(this.notificationLinkRender(notification.actor, notification.verb))
        })
      }
    },

    calculateDateDifference(notification_date) {
      return this.notificationDateRender((this.now - this.$dayjs(notification_date))/1000)
    }
  }
}
</script>
<style scoped>
.notification-card {
  cursor: pointer;
}

.notification-card:hover {
  color: #321fdb;
  background-color: #FAFAFA;
}

.dot {
   height: 11px;
   width: 11px;
   background-color: #321fdb;
   border-radius: 50%;
   display: inline-block;
}
</style>
