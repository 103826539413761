export default {
  methods: {

    notificationTitleRender(verb) {
      switch (verb) {
        case 'COURSECREATED':
          return 'Nouvelle course ajoutée'
        case 'COURSEORDERED':
          return 'Course commandée'
        case 'DOCUMENTCREATED':
          return 'Document chargé par votre client'
        case 'MISSIONAFFECTED':
          return 'Vous avez été affecté sur une mission'
        case 'PRIORITYAFFECTED':
          return 'Vous avez été affecté sur une priorité'
        case 'WELCOME':
          return 'Bienvenue sur Eclerk !'
      }
      return 'Erreur'
    },

    notificationDescriptionRender(notification) {
      if (notification.actor === null) {
        return ''
      }
      switch (notification.verb) {
        case 'COURSECREATED':
          return "Hey, c'est le moment de faire les course ! " + String(notification.actor.description) + " a été ajouté à la liste de courses"
        case 'COURSEORDERED':
          return "Youpi, votre course '" + String(notification.actor.description) + "' a été comandé"
        case 'DOCUMENTCREATED':
          return "Votre client " + String(notification.actor.client_name) + "' a chargé un document demandé"
        case 'MISSIONAFFECTED':
          return "Vous avez été affecté sur la mission " + String(notification.actor.name) + "' du client " + String(notification.actor.client_name)
        case 'PRIORITYAFFECTED':
          return "Vous avez été affecté sur une priorité de la mission " + String(notification.actor.mission_name) + "' du client " + String(notification.actor.client_name)
        case 'WELCOME':
          return String(notification.actor.company_name) + " vous souhaite la bienvenue sur son espace Eclerk"
      }
      return 'Erreur'
    },

    notificationLinkRender(actor, verb) {
      switch (verb) {
        case 'COURSECREATED':
          return "/logistique/courses/"
        case 'COURSEORDERED':
          return "/logistique/courses/"
        case 'DOCUMENTCREATED':
          return "/mission/" + actor.mission
        case 'MISSIONAFFECTED':
          return "/mission/" + actor.id
        case 'PRIORITYAFFECTED':
          return "/mission/" + actor.mission
        case 'WELCOME':
          return "/dashboard/"
      }
      return 'Erreur'
    },

    notificationDateRender(difference) {
      //difference is in second
      if (difference <= 600) {
        return 'Juste maintenant'
      }
      else if (difference <= 86400) {
        return 'Il y a ' + String(parseInt(difference/3600)) + ' heures'
      }
      else if (difference <= 2592000){
        return 'Il y a ' + String(parseInt(difference/86400)) + ' jours'
      }
      else if (difference <= 31536000){
        return 'Il y a ' + String(parseInt(difference/2592000)) + ' mois'
      }
      else {
        return 'Il y a ' + String(parseInt(difference/31536000)) + ' ans'
      }
    },
  }
}
